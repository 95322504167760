//
// Custom
// --------------------------------------------------
:root {
	--header-height: 90px;

	@media (min-width: 360px) {
		--header-height: 125px;
	}
}

.header {
	transition: height 0.3s;

	.header__container {
		max-width: 100%;
	}
}

@media (max-width: 1023px) {
	.header__nav {
		transition: opacity 0.3s;
		left: -101%;
		opacity: 0;
		display: block;

		&.header__nav--is-visible {
			left: 0;
			opacity: 1;
		}
	}
}

.header__logo {
	width: 75%;
}
@media (min-width: 480px) {
	.header__logo {
		width: auto;
	}
}

.header__link {
	transition: color 0.3s;
}

.text-component {
	h1,
	h2,
	.h2,
	h3,
	.h3,
	h4 {
		line-height: var(--heading-line-height);
		margin-top: 0;
	}

	.h2 {
		@extend h2;
	}

	h1 span, h2 span, h3 span {
		hyphens: auto;
	}

	ul,
	ol {
		list-style-position: outside;
		padding-left: var(--space-md);
	}

	ul:last-child,
	ol:last-child,
	p:last-child {
		margin-bottom: 0;
	}
}

h1 {
	text-shadow: 2px 2px 0 #fff;
}

.h3,
h3 {
	font-family: 'Caveat', cursive;
	font-size: var(--text-xxxl);

	> span {
		display: inline-block;
		// overflow: hidden;
		background-repeat: no-repeat;
		background-image: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyOTMuOSAzLjEiIHZpZXdCb3g9IjAgMCAyOTMuOSAzLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMS4zaDQxLjRjNy4zIDAgMTQuNS4xIDIxLjguMiAyLjUgMCA0LjgtLjIgNy4yLS4xIDQuNy4xIDkuNC4xIDE0LjIuMWgxMy4xYzMuNiAwIDcuNC4xIDExIDAgOC0uMiAxNi4zLS4yIDI0LjQtLjJoMTUuNyA4LjdjMy40IDAgNi44LjIgMTAuMi4yaDE3LjljMy45IDAgNy45LS4xIDExLjggMCAxLjEgMCAyLjMuMSAzLjQuMSAxLjUgMCAzLS4xIDQuNi0uMSA2LjUgMCAxMy0uNCAxOS40LS4zIDMuNyAwIDcuMy0uMiAxMS0uMiA0IDAgNy44LjMgMTEuOC4zczggLjIgMTIgLjJjNS43IDAgMTEuMy40IDE3IC4zIDMuNiAwIDcuMy4xIDEwLjkuMWg1LjYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZiY2ExMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz48L3N2Zz4=);
		background-size: 80%;
		background-position: 0 bottom;
		text-shadow: 1px 1px 0 #fff;
	}
}

.h3 {
	font-size: var(--text-xl);
}

h4 {
	font-family: 'Caveat', cursive;
	font-size: var(--text-lg);
	// color: var(--color-primary);

	> span {
		display: inline-block;
		// overflow: hidden;
		background-repeat: no-repeat;
		background-image: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyOTMuOSAzLjEiIHZpZXdCb3g9IjAgMCAyOTMuOSAzLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMS4zaDQxLjRjNy4zIDAgMTQuNS4xIDIxLjguMiAyLjUgMCA0LjgtLjIgNy4yLS4xIDQuNy4xIDkuNC4xIDE0LjIuMWgxMy4xYzMuNiAwIDcuNC4xIDExIDAgOC0uMiAxNi4zLS4yIDI0LjQtLjJoMTUuNyA4LjdjMy40IDAgNi44LjIgMTAuMi4yaDE3LjljMy45IDAgNy45LS4xIDExLjggMCAxLjEgMCAyLjMuMSAzLjQuMSAxLjUgMCAzLS4xIDQuNi0uMSA2LjUgMCAxMy0uNCAxOS40LS4zIDMuNyAwIDcuMy0uMiAxMS0uMiA0IDAgNy44LjMgMTEuOC4zczggLjIgMTIgLjJjNS43IDAgMTEuMy40IDE3IC4zIDMuNiAwIDcuMy4xIDEwLjkuMWg1LjYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZiY2ExMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz48L3N2Zz4=);
		background-size: 80%;
		background-position: 0 bottom;
		text-shadow: 1px 1px 0 #fff;
	}
}

.hero-override {
	background-position: center 80px;
}

.overscroll-section__sticky-content {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: #000000;

	// When fading content below, a pixel line is shown,
	// by placing a border bottom the line disapears
	border-bottom: 1px solid #fff;
}

.overscroll-section_hardy {
	background-color: #fff;
	h2 {
		text-shadow: 2px 2px 0 #fff;
	}
}

.overscroll-section_hubert {
	background-color: #fff;
	h2 {
		text-shadow: 2px 2px 0 #fff;
	}
}

.card-v9--overlay-bg {
	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255, 0.75);
		transition: background-color 0.3s;
	}
	&:hover::before {
		background-color: alpha(var(--color-bg), 0.25);
	}

	h2, p {
		text-shadow: 2px 2px 0 #fff;
	}
}

.main-footer {
	background-color: var(--color-primary-lighter);

	div[class*="display@sm"] {
		text-align: right;
	}

	a {
		color: #000;
	}

	.border-top {
		border-top: 0;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -15px;
			left: 0;
			width: 100%;
			height: 10px;
			background-repeat: no-repeat;
			background-image: url(data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyOTMuOSAzLjEiIHZpZXdCb3g9IjAgMCAyOTMuOSAzLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMS4zaDQxLjRjNy4zIDAgMTQuNS4xIDIxLjguMiAyLjUgMCA0LjgtLjIgNy4yLS4xIDQuNy4xIDkuNC4xIDE0LjIuMWgxMy4xYzMuNiAwIDcuNC4xIDExIDAgOC0uMiAxNi4zLS4yIDI0LjQtLjJoMTUuNyA4LjdjMy40IDAgNi44LjIgMTAuMi4yaDE3LjljMy45IDAgNy45LS4xIDExLjggMCAxLjEgMCAyLjMuMSAzLjQuMSAxLjUgMCAzLS4xIDQuNi0uMSA2LjUgMCAxMy0uNCAxOS40LS4zIDMuNyAwIDcuMy0uMiAxMS0uMiA0IDAgNy44LjMgMTEuOC4zczggLjIgMTIgLjJjNS43IDAgMTEuMy40IDE3IC4zIDMuNiAwIDcuMy4xIDEwLjkuMWg1LjYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZiY2ExMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz48L3N2Zz4=);
			background-size: 100%;
		}
	}
}

.main-footer__logo {
	height: 100px;
	width: auto;
	margin-left: auto;
	margin-right: 0;

	> svg {
		margin-left: auto;
	}
}
